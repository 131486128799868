<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
        <div class="q-pa-md page_main">
            <q-card class="q-pa-md page_contents" style="min-width : 900px" >
                <q-card-section>
                    <q-bar class="bg-white text-black">
                        <p class="text-black q-mr-sm" style="min-width:40px">제목</p>
                        <q-input v-model="form.Title" outlined style="width: 45%;"
                            clearable @clear="clear_input(form.Title)"
                            @keydown.tab.prevent="FocusOnEditor"
                            :error="form_error.Title.length > 0" :error-message="form_error.Title">
                        </q-input>
                        <q-space />
                        <p class="text-black q-mr-sm">앱</p>
                        <q-select outlined style="min-width: 160px;" class="bg-white q-mr-sm" v-model="select_app_title" 
                                :options="app_list" @update:model-value="selectApp"
                                :error="form_error.App.length > 0" :error-message="form_error.App">
                            <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps">
                                    <q-item-section>
                                        <q-item-label>{{ scope.opt.Value }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                        <p class="text-black q-mr-sm" style="min-width:40px">요청</p>
                        <q-select outlined style="min-width: 100px;" class="q-mr-sm" 
                                    @update:model-value="select_type"
                                    :options="type_list" v-model="form.Type" 
                                    :error="form_error.Type.length > 0" :error-message="form_error.Type">
                        </q-select>
                        <p class="text-black q-mr-sm" style="min-width:40px">메뉴</p>
                        <q-select outlined style="min-width: 150px;" 
                                    @update:model-value="select_page"
                                    :options="page_list" v-model="form.Page" 
                                    :error="form_error.Page.length > 0" :error-message="form_error.Page">
                        </q-select>
                    </q-bar>
                </q-card-section>
                <q-card-section style="padding-top: 0px;">
                    <quill_editor :height="450" ref="quill_editor"></quill_editor>
                    <!-- <tiptap_editor /> -->
                </q-card-section>
                <q-card-section>
                    <div style="text-align: center">
                        <q-btn icon="save" label="저장" size="md" class="q-mr-md" color="positive" 
                                @click="handleSave(0)"/>
                        <q-btn icon="close" label="취소" size="md" class="q-mr-md" color="positive" 
                                @click="close()"/>
                        <q-btn icon="cloud" label="임시저장" size="md" class="q-mr-md" color="positive" 
                                @click="handleSave(1)" />
                    </div>
                </q-card-section>
            </q-card>
        </div>

	</q-page-container>
  </q-layout>
</template>



<script>
import axios from "axios";
import quill_editor from '../../shared/quill_editor.vue'
import tiptap_editor from '../../shared/tiptap_editor.vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css';

export default {
    name: 'ReportView',
    data() {
        return {
            search: '',
            form: {
                Title: '',
                Type: '수정',
                Page: '',
                Content: '',
                DisplayName: '',
                IsTentative: 0,
                SettingId: 0,
                App: '',
            },
            center: null,

            form_error: {
                Title: '',
                Type: '',
                Page: '',
                Content: '',
                App: '',
                
            },

            type_list: ['추가', '수정', '오류', '기타'],
            page_list: ['홈화면', '전체관리', '학생관리', '신입생관리', '퇴원생관리',
                        '교과반관리', '관리반관리', '관심학생관리', '수납관리', '상담관리',
                        '교재관리', '교구관리', '메시지관리', '직원관리', '설정', '공지사항', 
                        '미분류'],
            
            select_app_title: "",
            editor: null,
        };
    },
    components: {
        quill_editor,
        tiptap_editor,
    },
    computed: {
        app_list: function() {
            let cm_index = this.$store.state.app_list.findIndex(i=>i.Value=="클래스매니저");
            this.form.App = this.$store.state.app_list.length > 0 ? this.$store.state.app_list[cm_index].SettingId : "";
            this.select_app_title = this.$store.state.app_list.length > 0 ? this.$store.state.app_list[cm_index].Value : "";
            return this.$store.state.app_list;
        },  
    },
    methods: {
        close: function() {
            var vm = this;
            var content = vm.$refs.quill_editor.QuillEditor.__quill.getContents();
            if(content["ops"][0]["insert"] != '\n') {
                vm.$q.dialog({
                    title: '확인',
                    message: '이 페이지를 벗어나면 마지막 저장 후 수정된 내용은 저장되지 않습니다.',
                    cancel: true,
                    persistent: true
                }).onOk(() => {
                    vm.$router.back();
                    // vm.$router.push("/report");
                }).onCancel(() => {
                    
                });
            } else {
                vm.$router.back();
                // vm.$router.push("/report");
            }
        },
        clear_error: function() {
            for(var k in this.form_error) {
                this.form_error[k] = "";
            }
        },
        selectApp: function(row) { 
            this.select_app_title = row.Value; 
            this.form.App = row.SettingId;
        },
        select_type: function(val) {this.form.Type = val;},
        select_page: function(val) {this.form.Page = val;},
        clear_input: function() { var vm = this; },
        
        FocusOnEditor: function(){      //Tab을 누를 경우 본문으로 Focus 이동
            var vm = this;
            vm.$refs.quill_editor.QuillEditor.__quill.focus();
        },
        handleSave: function(IsTentative) {
            var vm = this;
            var html = vm.$refs.quill_editor.QuillEditor.__quill.root.innerHTML;
            vm.form.Content = html;
            vm.form.IsTentative = IsTentative;
            vm.clear_error();
            var CenterId = vm.$store.state.CenterId;
            var center_list = vm.$store.state.center_list;
            var center = new Object();
            for(var i=0; i<center_list.length; i++) {
                if(center_list[i].CenterId == CenterId) {
                    center = center_list[i];
                    break;
                }
            }
            vm.form.CenterId = center.CenterId;
            vm.form.CenterName = center.CenterName;
            vm.form.DisplayName = vm.$store.state.DisplayName;
            axios.put('/api/report/0', vm.form).then(function(response) {
                var data = response.data;
                if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
                    vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
                    vm.$router.push("/user/login");
                    return;
                }
                if(data.success) {
                    axios.put('/api/system/send/multi',{
						toUID : ['303EC07C-C46A-4485-82BB-5A24B8BFC58D ','7C4DDECF-ED63-4293-AD2D-58D7A5023340'],
						MessageData : `${vm.$store.state.DisplayName}님이 새 글을 작성하였습니다. 제목 : ${vm.form.Title}\nURL : ${window.location.href.replace('add','detail')}/${data.code}`,
						CenterId : 13
					}).then(function (response) {
                        let send_response = response.data;
                        if (send_response.success) {
                            vm.$c.response_notify(vm.$q, data.success, data.message);
                            vm.$router.push("/report");
                        } else {
                            if (send_response.hasOwnProperty('error')) {
                                for (var k in send_response.error) {
                                    if (vm.form_error.hasOwnProperty(k)) {
                                        vm.form_error[k] = send_response.error[k];
                                    }
                                }
                            }
                            if (send_response.hasOwnProperty('message')) {
                                vm.$c.response_notify(vm.$q, send_response.success, send_response.message);
                            }
                        }
                    })
                } else {
                    if (data.hasOwnProperty('error')) {
                        for(var k in data.error) {
                            if(vm.form_error.hasOwnProperty(k)) {
                                vm.form_error[k] = data.error[k];
                            }
                        }
                    }
                    if(data.hasOwnProperty('message')) {
                        vm.$c.response_notify(vm.$q, data.success, data.message);
                    }
                }
            })
        },
    },
    beforeMounted : function() {
		this.$c.form.init_error(this.form, this.form_error);
	},
    mounted: function() {
        var vm = this;
    },
}
</script>

<style>
.q-bar--standard{
    font-size:15px;
}

</style>
