<template>
	<q-list class="text-grey-3" style="padding-top: 50px">
		<q-item clickable v-ripple style="height: 40px;" v-for="(menu, index) in left_menu" :key="index"
			:class="{selection:menu.is_selected}" @click="goto_link(menu)">
			<q-item-section avatar>
				<q-icon color="white" :name="menu.icon"></q-icon>
			</q-item-section>
			<q-item-section>{{menu.title}}</q-item-section>
		</q-item>
		<!-- <q-expansion-item group="menu" class="left_menu pb2" expand-separator :class="{active:menu.is_selected}"
							:icon="menu.icon" :label="menu.title" v-model="menu.is_selected"
							@click="goto_link(menu)">
			<q-item clickable v-ripple @click="goto_link(item)" v-for="item in menu.children">
				<q-item-section avatar></q-item-section>
				<q-item-section> {{ item.title }} </q-item-section>
				<template v-if="item.children.length>0">
					<q-item-section side >
						<q-icon name="keyboard_arrow_right" ></q-icon>
					</q-item-section>
					<com-left_pop_menu :menu="item"></com-left_pop_menu>
				</template>
			</q-item>
		</q-expansion-item> -->
		
	</q-list>
</template>

<style>
.selection {
	border-left: 3px solid #6C6CB9;
}
.q-item__section--avatar {
    min-width: 40px;
}
.q-expansion-item.active {
	background-color:#333;
}
.q-expansion-item.left_menu > .q-expansion-item__container > .q-item {
	min-height:36px;
}
</style>

<script>
export default {
	name : "com-left_menu",
	data : function() {
		return {
			left_menu : [
				// { title : '검색', icon : 'search', route:"search", is_selected: false,
				// 	link : '/search' },
				{ title : '리포팅', icon : 'format_list_bulleted', route:"report", is_selected: false,
					link : '/report' },
				{ title : '공지', icon : 'notifications', route:"notice", is_selected: false,
					link : '/notice' },
				{ title : '내 작업', icon : 'person', route:"myPage", is_selected: false,
					link : '/myPage' },
				// { title : '설정', icon : 'settings', route:"setting", is_selected: false,
				// 	link : '/setting' },
			],
			
		}
	},
	methods : {
		leftMenu_click : function(route) {
			var i;
			for(i=0; i<this.left_menu.length; i++) {
				if( route==this.left_menu[i].route) {
					if( this.left_menu[i].is_selected==true ) {
						this.left_menu[i].is_selected = false;
					} else {
						this.left_menu[i].is_selected = true;
					}
				} else {
					this.left_menu[i].is_selected = false;
				}
			}
		},
		goto_link : function(menu) {
			if(menu.link) {
				this.$router.push(menu.link);
			}
		},
	},
	mounted : function() {
		var route_list = this.$route.path.split('/');
		var route = '';
		if(route_list.length>=2) { route = route_list[1]; }
		this.leftMenu_click(route);
	},
}
</script>