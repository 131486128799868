<template>
	<q-btn flat :label="UserName" color="black" icon="account_circle">
		<q-menu :offset="[0, 10]" >
			<q-list style="min-width: 100px">
				<q-item clickable @click="click_logout">
					<q-item-section>Logout</q-item-section>
				</q-item>
			</q-list>
		</q-menu>
	</q-btn>
</template>

<script>
export default {
	name : "com-top_menu",
	data : function() {
		return {
			UserName: "",
		}
	},
	methods : {
		click_logout : function() {
			let vm = this;
			vm.$c.io.post('/api/user/logout', {}).then(function(response) {
				vm.$router.push('/user/login');
			});
		},
	},
	mounted: function() {
		var vm = this;
		vm.UserName = this.$c.cookie.get('user_name');
	},
}
</script>