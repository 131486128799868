<template>
  <router-view />
</template>

<script>
import { ref } from 'vue'
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import "./assets/libs/vue/ag-grid-community/dist/styles/ag-grid.css";
import "./assets/libs/vue/ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./assets/libs/vue/ag-grid-community/dist/ag-grid-community.min.js";
import { LicenseManager } from  'ag-grid-enterprise';
LicenseManager.setLicenseKey("CompanyName=hamo,LicensedGroup=hamo,LicenseType=MultipleApplications,LicensedConcurrentDeveloperCount=1,LicensedProductionInstancesCount=0,AssetReference=AG-028008,ExpiryDate=9_May_2023_[v2]_MTY4MzU4NjgwMDAwMA==dc1c62089719b8c9b6ceaaa0ecb98d3e");
// import { AgGridVue } from "./assets/libs/vue/ag-grid-vue3/dist/ag-grid-vue3.umd.js";

export default {
  components: {
  },

  setup () {
    //global 데이터 로딩
    const store = useStore();
    const router = useRouter();
    store.dispatch('get_user', function(data) {
      if( !store.state.UID ) {
        router.push('/user/login');
      } else {
        
      }
    });
    store.dispatch('get_center_list');
    store.dispatch('get_course_group_list');
    store.dispatch('get_app_list');
    
    return {
    }
  }
}
</script>
