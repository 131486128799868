<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
        <div class="q-pa-md page_main">
            <q-card class="page_contents">
                <q-card-section>
                    <div style="width: 100%; display: inline-flex; text-align:center;">
                        <q-input v-model="search" outlined  placeholder="검색" 
                                style="width: 250px;" clearable @clear="clear_search"
                                @keyup.enter.prevent="keyup_search">
                            <template v-slot:prepend>
                                <q-icon name="search" />
                            </template>
                        </q-input>
                        <q-btn icon="search" flat dense/>
                        <span>상세검색</span>
                    </div>
                </q-card-section>
            </q-card>
        </div>

	</q-page-container>
  </q-layout>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import axios from "axios";

export default {
    name: 'ReportView',
    data() {
        return {
            search: '',
        };
    },
    components: {
    },
    methods: {
        clear_search: function() {
            var vm = this;
            vm.search = "";
            vm.load_report_list();
        },
        keyup_search: async function() {
            var vm = this;

            
        },
    },
    mounted: function() {
        var vm = this;
    },
}
</script>
