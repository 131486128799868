import { vModelText } from 'vue';
import { createStore } from 'vuex'
import $c from '../assets/libs/common.js';

export default createStore({
	state: {
		UID : "",
		DisplayName : "",
		IsAdmin: false,
		CenterId : "",
		Role : "",
		RoleId : "",
		User : {},
		language_id : "",
		date_format : "",
		time_format : "",
		decimal_place : "",
		i18n : {},
		/* center */
		center_list : [],
		center_dict: {},
		/* course group */
		course_group_list : [],
		app_list: [],
		notice_write_permission: {
			"8ECAA588-4F3B-4243-A899-B2676C83DDC1": true, // 전상현 이사님
			"303EC07C-C46A-4485-82BB-5A24B8BFC58D": true, // 김기혁 팀장님
			"7C4DDECF-ED63-4293-AD2D-58D7A5023340": true, // 박재현
		}
	},
	mutations: {
		i18n(state, i18n) {
			state.i18n[i18n.url] = i18n.value;
		},
	},
	actions : {
		get_user(context, callback) {
			$c.io.get( '/api/user/info', {
				
			}).then( function(response) {
				let data = response.data;
				if(data['success']) {
					if(data.user) {
						context.state.UID = data.user.UID;
						context.state.CenterId = data.user.CenterId;
						context.state.DisplayName = data.user.DisplayName;
						context.state.IsAdmin = data.user.IsAdmin;
					} else {
						context.state.UID = '';
						context.state.CenterId = 0;
						context.state.DisplayName = '';
					}
				}
				if(callback) {
					callback(data);
				}
			});
		},
		
		get_i18n(context, options) {
			let url =  options.url;
			let callback = options.callback;
			if( context.state.i18n.hasOwnProperty(url) ) {
				callback(context.state.i18n[url]);
				return;
			}
			$c.io.get( '/api/system/localization/languages/cfg', {url:url}).then( function(response) {
				var data = response.data;
				if(data['success']) {
					context.commit('i18n', {url:url, value:data['i18n']}) ;
					callback(context.state.i18n[url]);
				}
			});
		},

		get_center_list(context) {
			$c.io.get( '/api/setting/centers', {}).then( function(response) {
				var data = response.data;
				if(data['success']) {
					for(let i=0; i<data.center_list.length; i++) {
						let center = data.center_list[i];
						center.label = center.CenterName;
						center.value = center.CenterId;
						context.state.center_dict[center.CenterId] = center;
					}
					context.state.center_list = data.center_list;
				}
			});
		},
		get_course_group_list(context) {
			$c.io.get( '/api/setting/course_groups', {}).then( function(response) {
				var data = response.data;
				if(data['success']) {
					for(let i=0; i<data.course_group_list.length; i++) {
						let course_group = data.course_group_list[i];
						course_group.label = course_group.Title;
						course_group.value = course_group.CourseGroupId;
						course_group.course_list = [];
						course_group.course_tag_list = [];
					}
					context.state.course_group_list = data.course_group_list;
				}
			});
		},
		
		get_app_list(context) {
            var vm = this;
            $c.io.get('/api/settings/' + 'App', {
                params: {
                    
                }
            }).then(function(response) {
                var data = response.data;
                if(data.success) {
                    var row = data.setting_list;
                    for(let i=0; i<row.length; i++) {
                        if(row[i].Keyword2 == '공지사항') {
                            if(context.state.notice_write_permission.hasOwnProperty(context.state.UID) == false) {
                                row.splice(i, 1);
                                break;
                            }
                        }
                    }
                    context.state.app_list = row;
                }
            });
        },
	}
})
