<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
        <div class="q-pa-md page_main">
            <div class="row q-mb-md" style="align-items: center;">
                <span>내작업 > &nbsp;</span>
                <q-select style="width: 200px;" class="bg-white" outlined v-model="select_app_title" 
                        :options="app_list" @update:model-value="selectApp">
                    <template v-slot:option="scope">
                        <q-item v-bind="scope.itemProps">
                            <q-item-section>
                                <q-item-label>{{ scope.opt.Value }}</q-item-label>
                            </q-item-section>
                        </q-item>
                    </template>
                </q-select>
                <q-space></q-space>
                <q-input v-model="search" class="bg-white" outlined placeholder="제목 또는 코드로 검색해주세요." 
                        style="width: 300px;" clearable @clear="clear_search"
                        @keyup.enter.prevent="handleSearch">
                    <template v-slot:prepend>
                        <q-icon name="search" />
                    </template>
                </q-input>
                <!-- <q-toggle v-model="include_complete" checked-icon="check" color="red"
                        label="완료포함" unchecked-icon="clear" @update:model-value="change_complete"/> -->
            </div> 
            <q-card class="page_contents">
                <q-card-section>
                    <div style="display: flex;">
                        
                        <q-space></q-space>
                        <q-btn icon="add" color="positive" label="추가" @click="location_href('/report/add')"></q-btn>
                    </div>
                </q-card-section>
                <q-card-section>
                    <ag-grid-vue style="width: 100%; height: 100%;" 
                            class="ag-theme-alpine" id="myGrid" @grid-ready="onGridReady"
                            :columnDefs="columnDefs" :rowData="report_list" 
                            @row-double-clicked="doubleClikedRow"    
                            rowSelection='single'
                            :masterDetail="true" 
                            :defaultColDef="defaultColDef"
                            :detailRowAutoHeight="true"
                            domLayout="autoHeight"
                            :animateRows="true"
                            :suppressMenuHide="true"
                            :detailCellRenderer="detailCellRenderer" 
                            ref="report_list">
                    </ag-grid-vue>
                </q-card-section>
                <q-card-section>
                    <div style="display: flex; justify-content: space-between;">
                        <div style="display: inline-block">
                            <span class="q-pr-md">보기</span>
                            <q-select 
                                outlined style="width:100px; display: inline-block;" @update:model-value="change_limit"
                                :options="limit_count" v-model="pagination.Limit" >
                                <!-- <template #option="scope">
                                    <q-item v-bind="scope.itemProps" @click="form.CenterId=scope.opt.CenterId" >
                                        <q-item-section >
                                            {{scope.opt.CenterName}}
                                        </q-item-section>
                                    </q-item>
                                </template> -->
                            </q-select>
                        </div>
                        <div>
                             <q-pagination v-model="pagination.Page" direction-links boundary-links
                                :max="pagination.TotalPages" @update:model-value="update_pagination"
                                icon-first="skip_previous" icon-last="skip_next" icon-prev="fast_rewind" icon-next="fast_forward"/>
                        </div>
                    </div>
                </q-card-section>
            </q-card>
        </div>
	</q-page-container>
  </q-layout>
</template>

<script>
import { AgGridVue } from "ag-grid-vue3";
import axios from "axios";
import statusComponent from '../shared/statusComponent.vue';
import DetailCellRenderer from '../shared/detailCellRendererVue.vue';
import CustomHeader from '../shared/customHeaderVue.vue';

export default {
    name: 'ReportView',
    data() {
        return {
            App: 0,
            search: '',
            columnDefs: [
                { field: "FeedbackId", headerName: "코드", width: 150, sortable: true,
                        cellRenderer: 'agGroupCellRenderer', lockPosition: 'left',
                        headerComponentParams: { field: 'FeedbackId', changeSort: this.changeSort }, },
                { field: "Title", headerName: "제목", width: 250, sortable: true, lockPosition: 'left',
                        headerComponentParams: { field: 'Title', changeSort: this.changeSort }, },
                { field: "CommentCount", headerName: "댓글", width: 100, sortable: false, 
                        lockPosition: 'left', },
                { field: "AppTitle", headerName: "앱", width: 100, sortable: true, lockPosition: 'left',
                        headerComponentParams: { field: 'AppTitle', changeSort: this.changeSort }, },

                { field: "CreatedDateView", headerName: "작성날짜", width: 120, sortable: true, 
                    lockPosition: 'left',},
                { field: "CompleteDateView", headerName: "완료날짜", width: 120, sortable: true,
                    lockPosition: 'left', },
                { field: "Status", headerName: "상태", width: 100, sortable: false,
                    cellRenderer: 'statusComponent', lockPosition: 'left', },
                { field: "Page", headerName: "메뉴", width: 120, sortable: false, lockPosition: 'left', },
                { field: "Type", headerName: "분류", width: 100, sortable: false, lockPosition: 'left', },
                { field: "Writer", headerName: "작성자", width: 100, sortable: false, lockPosition: 'left', },
                { field: "CenterName", headerName: "소속", width: 150, sortable: false, lockPosition: 'left', },
                { field: "ReleaseDateView", headerName: "릴리즈일정", width: 150, sortable: false, 
                    lockPosition: 'left', },
            ],
            report_list: [],
            pagination: {
                TotalCount: 0,
                TotalPages: 1,
                Page: 1,
                Limit: 25,
                Offset: 0,
            },
            limit_count: [25,50,100,300],
            detailCellRenderer: null,
            defaultColDef: {
                enableRowGroup: false,
                enablePivot: false,
                enableValue: false,
                sortable: true,
                filter: false,
                resizable: true,
                editable: false,
                lockPinned: true            // moveable 막음
            },

            gridApi: null,
            gridColumnApi: null,

            select_app_title: "",
        };
    },
    components: {
        AgGridVue,
        statusComponent,
        DetailCellRenderer,
        agColumnHeader: CustomHeader,
    },
    computed: {
        UID: function() {
            return this.$store.state.UID;
        },
        app_list: function() {
            var app_list = [
                { Keyword: "All", Keyword2: "All", Value: "전체 앱", SortOrder: 0 }
            ]
            this.select_app_title = "전체 앱";
            this.App = 0;
            app_list = app_list.concat(this.$store.state.app_list);
            return app_list;
        },  
    },
    methods: {
        changeSort: function(field, order) {
            var vm = this;
            vm.sort_field = [field, order];
            vm.load_report_list();
        },
        
        location_href: function(url) {
            var vm = this;
            vm.$router.push(url);
        },

        selectApp: function(row) {
            var vm = this;
            vm.select_app_title = row.Value;
            vm.App = row.SettingId;
            vm.load_report_list();
        },

        clear_search: function() {
            var vm = this;
            vm.search = "";
            vm.load_report_list();
        },

        change_limit: function(limit) {
            var vm = this;
            vm.pagination.Limit = limit;
            vm.load_report_list();
        },

        doubleClikedRow: function(e) {
            var vm = this;
            var data = e.data;
            vm.$router.push("/report/detail/" + data.FeedbackId);
        },

        

        handleSearch: async function() {
            var vm = this;
            vm.pagination.Page = 1;
            vm.load_report_list();
        },
        update_pagination: function(page) {
            var vm = this;
            if(isNaN(page)) {
                vm.pagination.Page = 1;
            } else {
                vm.pagination.Page = page;
            }
            vm.load_report_list();
        },
        load_report_list: function(callback) {
            var vm = this;
            vm.$q.loading.show();
            axios.get("/api/own/report", {
                params: {
                    pagination: vm.pagination,
                    include_complete: vm.include_complete,
                    search: vm.search,
                    App: vm.App
                }
            }).then(function(response) {
                var data = response.data;
                if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
                    vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
                    vm.$router.push("/user/login");
                    return;
                }
                if(data.success) {
                    for(var k in data.pagination) {
                        vm.pagination[k] = data.pagination[k];
                    }
                    var row = data.report_list;
                    for(var i=0; i<row.length; i++) {
                        var created_date = row[i].CreatedDate.substring(0,10).toDate();
                        row[i]["CreatedDateView"] = vm.$c.formatDate(created_date, "YYYY/MM/DD");
                        if(row[i].ReleaseDate) {
                            var release_date = row[i].ReleaseDate.substring(0,10).toDate();
                            row[i]["ReleaseDateView"] = vm.$c.formatDate(release_date, "YYYY/MM/DD");
                        } else {
                            row[i]["ReleaseDateView"] = "";
                        }
                    }
                    vm.report_list = row;
                    console.log("report_list:", vm.report_list);
                    if(callback) { callback(); }
                }
                vm.$q.loading.hide();
            });
        },
        onGridReady(params) {
            this.gridApi = params.api;
            this.gridColumnApi = params.columnApi;
        },
    },
    mounted: function() {
        var vm = this;
        vm.load_report_list();
    },
    created: function() {
        this.detailCellRenderer = DetailCellRenderer;
    },  
}
</script>
