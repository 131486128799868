<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
        <div class="q-pa-md page_main">
            <q-card class="q-pa-md page_contents" style="min-width: 1250px;">
                <q-card-section>
                    <q-bar class="bg-white text-black">
                        <p class="text-black q-mr-sm">제목</p>
                        <q-input v-model="form.Title" outlined style="width: 45%;" :disable="true"
                            clearable @clear="clear_input(form.Title)"
                            :error="form_error.Title.length > 0" :error-message="form_error.Title">
                        </q-input>
                        <q-space />
                        <p class="text-black q-mr-sm">앱</p>
                        <q-select outlined style="width: 150px;" class="bg-white q-mr-sm" v-model="select_app_title" 
                                :options="app_list" @update:model-value="selectApp" :disable="true"
                                :error="form_error.App.length > 0" :error-message="form_error.App">
                            <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps">
                                    <q-item-section>
                                        <q-item-label>{{ scope.opt.Value }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                        <p class="text-black q-mr-sm">요청</p>
                        <q-select outlined style="width: 150px;" class="q-mr-sm"  :disable="true"
                                    @update:model-value="select_type"
                                    :options="type_list" v-model="form.Type" 
                                    :error="form_error.Type.length > 0" :error-message="form_error.Type">
                        </q-select>
                        <p class="text-black q-mr-sm">메뉴</p>
                        <q-select outlined style="width: 150px;" :disable="true"
                                    @update:model-value="select_page"
                                    :options="page_list" v-model="form.Page" 
                                    :error="form_error.Page.length > 0" :error-message="form_error.Page">
                        </q-select>
                    </q-bar>
                </q-card-section>
                <q-card-section style="padding-top: 0px;">
                    문의내용
                    <quill_editor :height="450" :readOnly="true" ref="quill_editor"></quill_editor>
                    답변
                    <quill_editor :height="450" :readOnly="true" ref="quill_editor_reply"></quill_editor>
                </q-card-section>
                <q-card-section>
                    <div style="text-align: center">
                        <q-btn icon="save" label="수정" size="md" class="q-mr-md" color="positive" 
                                @click="handleSave" v-if="IsAdmin || form.WriterUID == UID"/>
                        <q-btn icon="close" label="닫기" size="md" class="q-mr-md" color="positive" 
                                @click="close"/>
                    </div>
                </q-card-section>
            </q-card>
        </div>

	</q-page-container>
  </q-layout>
</template>

<script>
import axios from "axios"
import quill_editor from '../../shared/quill_editor.vue'

export default {
    name: 'ReportView',
    data() {
        return {
            FeedbackId: 0,
            search: '',
            form: {},
            center: null,

            form_error: {
                Title: '',
                Type: '',
                Page: '',
                Content: '',
                WriterUID: '',
                App: '',
            },

            type_list: ['추가', '수정', '오류', '기타'],
            page_list: ['홈화면', '전체관리', '학생관리', '신입생관리', '퇴원생관리',
                        '교과반관리', '관리반관리', '관심학생관리', '수납관리', '상담관리',
                        '교재관리', '교구관리', '메시지관리', '직원관리', '설정', '공지사항', 
                        '미분류'],
            select_app_title: '',
        };
    },
    components: {
        quill_editor,
    },
    computed: {
        IsAdmin() {
            return this.$store.state.IsAdmin;
        },
        UID: function() {
            return this.$store.state.UID;
        },
        app_list: function() {
            return this.$store.state.app_list;
        },  
    },
    methods: {
        handleSave: function() {
            var vm = this;
            vm.$router.push("/report/edit/" + vm.FeedbackId);
        },
        close: function() {
            var vm = this;
            vm.$router.back();
            // vm.$router.push("/report");
        },

        selectApp: function(row) { 
            this.select_app_title = row.Value; 
            this.form.App = row.SettingId;
        },
        select_type: function(val) {this.form.Type = val;},
        select_page: function(val) {this.form.Page = val;},
        clear_input: function() { var vm = this; },
        
        
        load_report: function(FeedbackId) {
            var vm = this;
            vm.$q.loading.show();
            axios.get('/api/report/' + FeedbackId).then(function(response) {
                var data =response.data;
                if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
                    vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
                    vm.$router.push("/user/login");
                    return;
                }

                if(data.success == 0) {
                    vm.$q.dialog({
                        title: '에러',
                        message: data.message
                    }).onOk(() => {
                        vm.$router.push("/report");
                    }).onCancel(() => {
                        vm.$router.push("/report");
                    }).onDismiss(() => {
                        vm.$router.push("/report");
                    });
                }

                var content = data.report.Content;
                if(data.report.Content) {
                    var quill = vm.$refs.quill_editor.QuillEditor.__quill;
                    quill.setContents(content);
                    const delta = quill.clipboard.convert(content);
                    quill.setContents(delta, 'silent');
                }
                if(data.report.Reply.Content) {
                    var quill = vm.$refs.quill_editor_reply.QuillEditor.__quill;
                    quill.setContents(data.report.Reply.Content);
                    const delta = quill.clipboard.convert(data.report.Reply.Content);
                    quill.setContents(delta, 'silent');
                }
                //
                // vm.$refs.quill_editor.QuillEditor.__quill.disable();
                // vm.$refs.quill_editor_reply.QuillEditor.__quill.disable();
                if(vm.app_list.length > 0) {
                    vm.select_app_title = vm.app_list.find(x=>x.SettingId == data.report.App).Value;
                } else {
                    vm.select_app_title = "";
                }
                vm.form = data.report;
                vm.$q.loading.hide();
            });
        },
    },
    beforeMounted : function() {
		this.$c.form.init_error(this.form, this.form_error);
	},
    mounted: function() {
        var vm = this;
        vm.FeedbackId = vm.$router.currentRoute.value.params.FeedbackId;
        if(vm.FeedbackId == 0) {
            vm.$q.dialog({
                title: '에러',
                message: '경로가 잘못되었습니다.'
            }).onOk(() => {
                vm.$router.push("/report");
            }).onCancel(() => {
                vm.$router.push("/report");
            }).onDismiss(() => {
                vm.$router.push("/report");
            });
        }
        else { 
            vm.load_report(vm.FeedbackId);     
        }
    },
}
</script>

<style>
.q-bar--standard{
    font-size:15px;
}

</style>