<template>
	<div v-for="row, idx in comment">
	<q-item>
		<q-item-section avatar top>
				<q-avatar size="48px">
					<img src="https://cdn.quasar.dev/img/boy-avatar.png" />
				</q-avatar>
			</q-item-section>

			<q-item-section top class="col-2 ">
				<q-item-label class="q-mt-sm">{{row.Writer}}</q-item-label>
				<q-item-label caption>{{center_dict[row.CenterId].CenterName}}</q-item-label>
			</q-item-section>

			<q-item-section>
				<q-item-label v-if="row.EditMode" >
						<q-input outlined v-model="row.Content" autogrow
							@blur="blur_comment(row.Content, idx)"
							:error="form_error.Content.length > 0" :error-message="form_error.Content" />
					<q-btn label="저장" class="q-mr-md" dense @click="handle_comment_update(row, idx)"></q-btn>
					<q-btn label="취소" dense @click="handle_comment_cancel(idx)"></q-btn>
				</q-item-label>
				<q-item-label caption v-else>
					<pre>{{row.Content}}</pre>
				</q-item-label>
			</q-item-section>

			<q-item-section side v-if="row.WriterUID == UID">
				<div class="text-grey-8 q-gutter-xs">
					<q-btn size="12px"
								flat dense round icon="more_vert" style="position: relative;">
						<q-menu style="position: relative;">
							<q-list style="min-width: 100px">
								<q-item clickable v-close-popup @click="change_edit_mode(idx)">
									<q-item-section>수정</q-item-section>
								</q-item>
								<q-item clickable v-close-popup @click="handle_comment_delete(row)">
									<q-item-section>삭제</q-item-section>
								</q-item>
							</q-list>
						</q-menu>
					</q-btn>
				</div>
			</q-item-section>
		</q-item>
		<q-separator />
	</div>
</template>

<script>
import axios from 'axios'
import { shallowRef,  ref, computed } from 'vue'

export default {
  name: 'customHeaderVue',
	props: {
		data: { type: Array },
		center_dict: { type: Object },
		UID: { type: String },
	},
  data() {
    return {
			comment: [],
      form: {},	
			form_error: {
				Content: "",
			},
    }
  },
  components: {

	},
  computed: {
		
  },
  
  methods: {
		blur_comment: function() {
			var vm = this;
		},
		handle_comment_cancel: function(idx) {
			var vm=  this;
			vm.comment[idx].EditMode = false;
		},	
		change_edit_mode: function(idx) {
			var vm = this;
			console.log(idx);
			vm.comment[idx].EditMode = true;
		},
  },
	mounted() {
		this.comment = this.data;
  },
}
</script>
