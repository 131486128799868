<template>
  <q-layout view="hHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
		
        <div class="q-pa-md page_main" >

            <q-card class="q-pa-sm page_contents">
                
				<h5 class="text-center mb2">To-Dos</h5>
				<ul class="collection with-header">
					<li class="collection-header mb3">
							<q-input v-model="todo.Text" label="할일" >
								<template v-slot:append>
									<q-btn  @click="addTodo">Add</q-btn>
								</template>
							</q-input>
					</li>
					<li
						class="collection-item row"
						v-for="t in todos"
						:key="t.TodoId"
						:class="{ fade: t.isCompleted==1 }"
					>
						<span class="deleteIcon" @click="deleteToDo(t.TodoId)">&#10005;</span>
						{{t.Text}}
						<q-space />
						<span class="secondary-content">
							<label>
								<input
									type="checkbox"
									class="filled-in"
									:true-value="1"
									:false-value="0"
									v-model="t.isCompleted"
									@change="updateTodoItem(t)"
								/>
								<span></span>
							</label>
						</span>
					</li>
				</ul>
				
            </q-card>
        </div>

	</q-page-container>
  </q-layout>
</template>

<style>
.fade {
    opacity: 0.4 !important;
}
li {
    font-size: 1.3em;
}
.collection.with-header {
    max-width: 500px;
    margin: 0 auto;
}
.collection.with-header .collection-header {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    padding: 10px 20px;
}
ul>li {
    list-style-type: none;
}

.deleteIcon {
    margin-right: 10px;
    cursor: pointer;
}
.deleteIcon:hover {
    opacity: 0.5;
}
</style>

<script>
import axios from "axios";

export default {
    name: 'TodoListView',
    data() {
        return {
            todos: [],
            todo: {
                Text: ""
            }
        };
    },
    created() {
        this.getTodos();
    },
    methods: {
        addTodo() {
			console.log('add');
			let vm = this;
			let form_data = new FormData();
			form_data.append('Text', this.todo.Text);
			form_data.append('isCompleted', 0);
            axios.put('/api/todos/0', form_data).then(function(response) {
				var data = response.data;
				if(data.success) {
					vm.$c.response_notify(vm.$q, data.success, '추가하였습니다.');
					vm.getTodos();
					vm.todo.Text = '';
				} else {
					vm.$c.response_notify(vm.$q, data.success, data.message);
				}
			});
        },
        async getTodos() {
            let vm = this;
            axios.get('/api/todos', {}).then(function(response) {
				var data = response.data;
				if(data.success) {
					vm.todos = data.todo_list;
				}
			});
        },
        updateTodoItem(todo) {
			let vm = this;
			let TodoId = todo.TodoId;
			let form_data = new FormData();
			form_data.append('Text', todo.Text);
			form_data.append('isCompleted', todo.isCompleted);
			axios.put('/api/todos/'+TodoId, form_data).then(function(response) {
				var data = response.data;
				if(data.success) {
					vm.$c.response_notify(vm.$q, data.success, '상태를 변경하였습니다.');
					vm.getTodos();
				}
			});
        },
        deleteToDo(TodoId) {
            let vm = this;
            axios.delete('/api/todos/'+TodoId, {}).then(function(response) {
				var data = response.data;
				if(data.success) {
					vm.$c.response_notify(vm.$q, data.success, '삭제하였습니다.');
					vm.getTodos();
				}
			});
        }
    }
}
</script>
