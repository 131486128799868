<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
		
        <div class="q-pa-md page_main" >

            <q-card class="q-pa-sm page_contents">
                <q-card-section>
                    
                </q-card-section>
            </q-card>
        </div>

	</q-page-container>
  </q-layout>
</template>

<script>
export default {
  name: 'HomeView',
}
</script>
