import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '../views/HomeView.vue'

import ReportView from '../views/report/IndexView.vue'
import ReportAddView from '../views/report/add/IndexView.vue'
import ReportDetailView from '../views/report/detail/IndexView.vue'
import ReportEditView from '../views/report/edit/IndexView.vue'

import NoticeView from '../views/notice/IndexView.vue'

import SearchView from '../views/SearchView.vue'
import LoginView from '../views/LoginView.vue'
import TodoListView from '../views/TodoListView.vue'
import MyPageView from '../views/myPage/IndexView.vue'
import com_404 from '../views/404.vue'
import Loading from 'quasar/src/plugins/Loading.js';


//router는 component에 url params를 전달하기 위해서 선언함.
function loading_hide() {
  Loading.hide();
}

const routes = [
  {
    path: '/report',
    name: 'report',
    component: ReportView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/report/add',
    name: 'reportAdd',
    component: ReportAddView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/report/detail/:FeedbackId',
    name: 'reportDetail',
    component: ReportDetailView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/report/edit/:FeedbackId',
    name: 'reportEdit',
    component: ReportEditView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/myPage',
    name: 'myPage',
    component: MyPageView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/notice',
    name: 'notice',
    component: NoticeView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/user/login',
    name: 'login',
    component: LoginView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/todo',
    name: 'tod',
    component: TodoListView,
    beforeEnter:[loading_hide] 
  },
  {
    path: '/search',
    name: 'search',
    component: SearchView,
    beforeEnter:[loading_hide] 
  },

  /* 404 */
  { 
    path:'/:pathMatch(.*)*', 
    component: com_404, 
    beforeEnter:[loading_hide] 
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
