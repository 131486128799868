<template>
  <div>
    <input type="file" name="upload_file" ref="upload_file" @change="uploadImage" id="upload_file" hidden />
    <div>
      <q-bar class="bg-white">
        <q-btn dense icon="format_bold" @click="editor.chain().focus().toggleBold().run()" :class="{ 'bg-dark text-white': editor.isActive('bold') }">
          <!-- bold -->
        </q-btn>
        <q-btn dense icon="format_italic" @click="editor.chain().focus().toggleItalic().run()" :class="{ 'bg-dark text-white': editor.isActive('italic') }">
          <!-- italic -->
        </q-btn>
        <q-btn dense icon="strikethrough_s" @click="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
          <!-- strike -->
        </q-btn>
      <!-- <q-btn dense icon="" @click="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
        code
      </q-btn> -->
      <!-- <q-btn dense icon="" @click="editor.chain().focus().unsetAllMarks().run()">
        clear marks
      </q-btn>
      <q-btn dense icon="" @click="editor.chain().focus().clearNodes().run()">
        clear nodes
      </q-btn> -->
      <!-- <q-btn dense icon="" @click="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
        paragraph
      </q-btn> -->
      <q-btn  dense @click="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
        h1
      </q-btn>
      <q-btn  dense @click="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
        h2
      </q-btn>
      <q-btn  dense @click="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
        h3
      </q-btn>
      <q-btn  dense @click="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
        h4
      </q-btn>
      <q-btn  dense @click="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
        h5
      </q-btn>
      <q-btn  dense @click="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
        h6
      </q-btn>
      <q-btn  dense icon="format_list_bulleted" @click="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
        <!-- bullet list -->
      </q-btn>
      <q-btn  dense icon="format_list_numbered" @click="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
        <!-- ordered list -->
      </q-btn>
      <q-btn dense icon="code" @click="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
        <!-- code block -->
      </q-btn>
      <q-btn dense icon="format_quote" @click="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
        <!-- blockquote -->
      </q-btn>
      <q-btn dense icon="horizontal_rule" @click="editor.chain().focus().setHorizontalRule().run()">
        <!-- horizontal rule -->
      </q-btn>
      <!-- 
      <q-btn dense icon="" @click="editor.chain().focus().setHardBreak().run()">
        hard break
      </q-btn> -->
      <q-btn dense icon="undo" @click="editor.chain().focus().undo().run()">
        <!-- undo -->
      </q-btn>
      <q-btn dense icon="redo" @click="editor.chain().focus().redo().run()">
        <!-- redo -->
      </q-btn>
      <!-- <q-input @update:model-value="val => { file = val[0] }" filled type="file" class="bg-white"/> -->
      <q-btn dense icon="images" @click="uploadImage">

      </q-btn>
      </q-bar>
      
    </div>
    <editor-content :editor="editor" class="tiptap_border"></editor-content>
  </div>
</template>



<style>
  .ProseMirror {
    outline: 0;
  }
  .ProseMirror blockquote {
    padding-left: 1rem;
    border-left: 2px solid rgba(13,13,13,.1);
  }
  .is-active {
    background-color: black !important;
    color: white !important;
  }
  .tiptap_border {
    border-width: 3px;
    border-color: black;
    border-style: solid;
    border-top-left-radius: 0.75rem;
    border-top-right-radius: 0.75rem;
    padding: 1.25rem 1rem;
  }
  ul, ol { padding: 0 1rem; }
  ul>li { list-style-type: disc !important; }
  h1, h2, h3, h4, h5, h6 { line-height: 1.1; }

  code { background-color: rgba(#616161, 0.1); color: white; }

  pre { background: #0D0D0D; color: #FFF;
    font-family: 'JetBrainsMono', monospace; padding: 0.75rem 1rem;
    border-radius: 0.5rem;

    code { color: inherit; padding: 0;
      background: none;
      font-size: 0.8rem;
    }
  }

  img { max-width: 100%; height: auto; }

  blockquote { padding-left: 1rem; border-left: 2px solid rgba(#0D0D0D, 0.1); }

  hr { border-top: 2px solid rgba(#0D0D0D, 0.1) !important; margin: 2rem 0; }
</style>

<script>
import axios from "axios";
import { Editor, EditorContent } from '@tiptap/vue-3'

export default {
	name : "tiptap_editor",
	props: {
		// height: { type: Number, default: 100 },
		// readOnly: { type: Boolean, default: false },
	},
	data : function() {
		return {
      editor: null,
		}
	},
	methods : {
		uploadImage: function(e) {
      var vm = this;
      // const file = e.target.files[0];
      const file = e.target.file;
      return;
      var form_data = new FormData();
      form_data.append('upload_file', file);
      vm.$c.io.post('/api/quill/upload/image', form_data).then(function(response) {
          var data = response.data;
          if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
              vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
              vm.$router.push("/user/login");
              return;
          }
          const editor = vm.$refs.quill_editor.editor.__quill; // 에디터 객체 가져오기
          // 2. 현재 에디터 커서 위치값을 가져온다
          const range = editor.getSelection();
          var url = "/feedbackImages/" + data.fileName;
          // 가져온 위치에 이미지를 삽입한다
          editor.insertEmbed(range.index, 'image', url);
          if (document.getElementsByName("upload_file")[0].value != null) {
            document.getElementsByName("upload_file")[0].value = "";
          }
      }).catch(function(err) {
          console.log("err:", err);
      });
    },
	},
	components: {
    EditorContent,
	},
  beforeMount() {
    var vm = this;
    vm.editor = new Editor({
      content: `
        <h2> Hi there, </h2>
        <p>
          this is a <em>basic</em> example of <strong>tiptap</strong>. Sure, there are all kind of basic text styles you’d probably expect from a text editor. But wait until you see the lists:
        </p>
        <ul>
          <li>
            That’s a bullet list with one …
          </li>
          <li>
            … or two list items.
          </li>
        </ul>
        <p>
          Isn’t that great? And all of that is editable. But wait, there’s more. Let’s try a code block:
        </p>
        <pre><code class="language-css">body {
  display: none;
}</code></pre>
        <p>
          I know, I know, this is impressive. It’s only the tip of the iceberg though. Give it a try and click a little bit around. Don’t forget to check the other examples too.
        </p>
        <blockquote>
          Wow, that’s amazing. Good work, boy! 👏
          <br />
          — Mom
        </blockquote>
      `,
      extensions: [
          StarterKit,
      ],
    });
  },
	mounted: function() {
    var vm = this;
  },
  beforeUnmount() {
    this.editor.destroy();
  },
}
</script>