<template>
	<div>
		<q-header class="bg-white text-grey-5">
			<q-toolbar>
				<q-btn flat round icon="menu" @click="leftDrawerOpen=!leftDrawerOpen" ></q-btn>
				<q-toolbar-title>
						Report System
				</q-toolbar-title>
				<com-top_menu></com-top_menu>
			</q-toolbar>
		</q-header>
			
		<q-drawer class="text-grey-5" show-if-above v-model="leftDrawerOpen" side="left" 
			:breakpoint="700" bordered :width="200" style="background-color: #3A3952;">
			<!-- drawer content -->
			<q-scroll-area class="fit">
				<com-left_menu></com-left_menu>
			</q-scroll-area>
		</q-drawer>
	</div>
</template>

<script>
export default {
  data : function() {
    return {
      leftDrawerOpen : true,
    };
  },
  methods : {
  }
}
</script>