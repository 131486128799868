<template>
  <div class="q-pa-md">
    <p>문의내용</p>
    <div class="ql-container ql-snow">
      <div v-html="content" class="ql-editor"></div>
    </div>
    <p>답변</p>
    <div class="ql-container ql-snow">
      <div v-if="reply" v-html="reply.Content" class="ql-editor"></div>
    </div>
    <!-- <quill_editor :height="200" ref="quill_editor" :readOnly="true"></quill_editor>
    <p>답변</p>
    <quill_editor :height="200" ref="quill_editor_reply" :readOnly="true"></quill_editor> -->
    <div class="q-pa-md">
      <q-item>
        <q-item-section side>
          <q-avatar size="48px">
            <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
          </q-avatar>
        </q-item-section>
        <q-item-section>
          <q-item-label>{{DisplayName}}</q-item-label>
          <q-item-label caption>{{center.CenterName}}</q-item-label>
        </q-item-section>
      </q-item>
      <q-input v-model="form.Content" filled type="textarea" placeholder="댓글을 입력하세요." 
              :autogrow="true"
              :error="form_error.Content.length > 0" :error-message="form_error.Content"
              @keydown.enter="handleEnter">
        <template v-slot:append>
          <q-btn flat dense icon="edit" @click="handleComment"/>
        </template>
      </q-input>

      <div class="q-mt-md">
        <q-list bordered ref="comment_list">
          <!-- <customComment :data="params.data.Comments" :center_dict="center_dict" :UID="UID" ref="custom_comment"></customComment> -->

            <q-item v-for="(row, index) in params.data.Comments" :key="index">
              <q-item-section avatar top>
                <q-avatar size="48px">
                  <img src="https://cdn.quasar.dev/img/boy-avatar.png" />
                </q-avatar>
              </q-item-section>

              <q-item-section top class="col-2 ">
                <q-item-label class="q-mt-sm">{{row.Writer}}</q-item-label>
                <q-item-label caption>{{center_dict[row.CenterId].CenterName}}</q-item-label>
              </q-item-section>

              <q-item-section>
                <q-item-label v-if="row.EditMode" >
                    <q-input outlined v-model="row.Content" autogrow
                      @blur="blur_comment(row.Content, index)"
                      :error="form_error.comment_content.length > 0" :error-message="form_error.comment_content" />
                  <q-btn label="저장" class="q-mr-md" dense @click="handle_comment_update(row, index)"></q-btn>
                  <q-btn label="취소" dense @click="handle_comment_cancel(row, index)"></q-btn>
                </q-item-label>
                <q-item-label caption v-else>
                  <pre>{{row.Content}}</pre>
                </q-item-label>
                <!-- 숫자가 입력되면 white-space가 안먹음 -->
              </q-item-section>

              <q-item-section side v-if="row.WriterUID == UID">
                <div class="text-grey-8 q-gutter-xs">
                  <q-btn size="12px" :ref="'action_' + index"
                        flat dense round icon="more_vert" style="position: relative;">
                    <q-menu style="position: relative;">
                      <q-list style="min-width: 100px">
                        <q-item clickable v-close-popup @click="change_edit_mode(row)">
                          <q-item-section>수정</q-item-section>
                        </q-item>
                        <q-item clickable v-close-popup @click="handle_comment_delete(row,index)">
                          <q-item-section>삭제</q-item-section>
                        </q-item>
                      </q-list>
                    </q-menu>
                  </q-btn>
                </div>
              </q-item-section>
            </q-item>
            <q-separator/>
        </q-list>
      </div>
    </div>
    <div class="text-center q-mt-md">
      <q-btn icon="edit" label="수정" @click="edit_page" class="q-mr-md"
          color="positive" v-if="params.data.WriterUID == UID" />
      <q-btn icon="close" label="접기" class="q-mr-md" 
          color="positive" @click="changeCollapse" />
      <q-btn icon="shared" label="공유" color="positive" @click="open_shared_dialog"/>
    </div>

    <q-dialog v-model="is_open" persistent>
      <q-card style="width: 450px;">
        <q-toolbar>
          <q-toolbar-title class="q-pa-sm">
            <span class="text-weight-bold">공유</span>
          </q-toolbar-title>
          <q-btn flat round dense icon="close" v-close-popup />
        </q-toolbar>
        <q-separator></q-separator>
        <q-card-section class="text-center">
          <q-avatar size="100px" font-size="52px" color="white" text-color="black" icon="code" style="border: 1px solid black;" />
          <p>링크 URL</p>
        </q-card-section>
        <q-card-section>
          <q-bar style="height: 40px;">
            <q-input ref="copy_url" v-model="url" />
            <q-space />
            <q-btn flat label="복사" @click="copy_url"></q-btn>
          </q-bar>
        </q-card-section>
      </q-card>
    </q-dialog>
  </div>
</template>

<script>
import axios from 'axios'
import { shallowRef,  ref, computed } from 'vue'
import quill_editor from './quill_editor.vue'
import customComment from './customCommentVue.vue'

export default {
  name: 'detailCellRenderer',
  data() {
    return {
      url: "https://wpp.name/1",
      content: '',
      reply: null,

      form: {
        Content: '',
      },
      form_error: {
        Content: '',
        comment_content: '',
      },
      is_open: false,
    }
  },
  components: {
    quill_editor: shallowRef(quill_editor),
    customComment: shallowRef(customComment),
  },
  setup(props) {
    return {
    };
  },
  computed: {
    UID: function() {
      return this.$store.state.UID;
    },
    DisplayName: function() {
      return this.$store.state.DisplayName;
    },
    center: function() {
      var center = this.$store.state.center_list.find(x=>x.CenterId == this.$store.state.CenterId)
      return center;
    },
    center_dict: function() {
      return this.$store.state.center_dict;
    }
  },
  methods: {
    copy_url() {
      var vm = this;
      vm.$refs.copy_url.select();
      document.execCommand("copy");
    },
    open_shared_dialog: function() {
      var vm = this;
      vm.is_open = true;
    },
    blur_comment: function(text, idx) {
      var vm = this;
      vm.params.data.Comments[idx].Content = text;
      vm.$refs.comment_list.$forceUpdate();
    },
    changeCollapse: function() {
      var vm = this;
      var FeedbackId = vm.params.data.FeedbackId;
      //parent ??
      var node = vm.params.node.parent;
      node.setExpanded(false);
      // const gridApi = vm.params.columnApi.columnModel.gridApi
      // gridApi.forEachNode(function(node) {
      //   if(node.data.FeedbackId == FeedbackId) {
      //     node.setExpanded(false);
      //     return;
      //   }
      // });
    },
    change_edit_mode: function(row) {
      var vm = this;
      row.EditMode = true;
      vm.$refs.comment_list.$forceUpdate();
    },
    edit_page: function() {
      this.$router.push('/report/edit/' + this.params.data.FeedbackId);
    },
    handleEnter:function(event){
      if(event.ctrlKey){
        event.preventDefault();
        this.handleComment();
      }
    },
    handleComment: function () {
      var vm = this;
      vm.$q.loading.show();
      vm.form.DisplayName = vm.$store.state.DisplayName;
      axios.post('/api/comments/' + this.params.data.FeedbackId, {
        form: vm.form
      }).then(function (response) {
        var data = response.data;
        if (data.hasOwnProperty('is_logged') && data.is_logged == false) {
          vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
          vm.$router.push("/user/login");
          return;
        }
        if (data.success) {
          axios.put('/api/system/send', {
            toUID: vm.params.data.WriterUID,
            MessageData: `${vm.$store.state.DisplayName}님이 "${vm.params.data.Title}" 글에 댓글을 남겼습니다.\nURL : ${window.location.href}/detail/${vm.params.data.FeedbackId}`,
            CenterId: vm.$store.state.center_list.find(x => x.CenterName == vm.params.data.CenterName).CenterId
          }).then(function (send_res) {
            let send_response = send_res.data;
            if (send_response.success) {
              for (var k in vm.form) {
                vm.form[k] = "";
              }
              vm.params.data.Comments.push(data.comments);
              var node = vm.params.node;
              node.setDataValue('CommentCount', vm.params.data.Comments.length);
              vm.$refs.comment_list.$forceUpdate();
              vm.$c.response_notify(vm.$q, data.success, data.message);
            } else {
              if (send_response.hasOwnProperty('error')) {
                for (var k in send_response.error) {
                  if (vm.form_error.hasOwnProperty(k)) {
                    vm.form_error[k] = send_response.error[k];
                  }
                }
              }
              if (send_response.hasOwnProperty('message')) {
                vm.$c.response_notify(vm.$q, send_response.success, send_response.message);
              }

            }
          })
        } else {
          if (data.hasOwnProperty('error')) {
            for (var k in data.error) {
              if (vm.form_error.hasOwnProperty(k)) {
                vm.form_error[k] = data.error[k];
              }
            }
          }
          if (data.hasOwnProperty('message')) {
            vm.$c.response_notify(vm.$q, data.success, data.message);
          }
        }
        vm.$q.loading.hide();
      });
    },

    handle_comment_update: function(row, idx) {
      var vm = this;
      vm.$q.loading.show();
      vm.form.DisplayName = vm.$store.state.DisplayName;
      axios.put('/api/comments/' + row.CommentId, {
        form: row
      }).then(function(response) {
        var data = response.data;
        if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
            vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
            vm.$router.push("/user/login");
            return;
        }
        if(data.success) {
          data.comment["EditMode"] = false;
          vm.params.data.Comments[idx] = data.comment;
          vm.$refs.comment_list.$forceUpdate();
          vm.$c.response_notify(vm.$q, data.success, data.message);
        } else {
          if(data.hasOwnProperty('error')) {
            for(var k in data.error) {
                if(vm.form_error.hasOwnProperty(k)) {
                    vm.form_error[k] = data.error[k];
                }
            }
          }
          if(data.hasOwnProperty('message')) {
            vm.$c.response_notify(vm.$q, data.success, data.message);
          }
        }
        vm.$q.loading.hide();
      });
    },
    handle_comment_cancel: function(row, idx) {
      var vm = this;
      vm.$q.loading.show();
      axios.get('/api/comments/' + row.CommentId, {
        params: {
          
        }
      }).then(function(response) {
        var data = response.data;
        if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
            vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
            vm.$router.push("/user/login");
            return;
        }
        if(data.success) {
          data.comment["EditMode"] = false;
          vm.params.data.Comments[idx] = data.comment;
          vm.$refs.comment_list.$forceUpdate();
        } else {
          if(data.hasOwnProperty('error')) {
            for(var k in data.error) {
                if(vm.form_error.hasOwnProperty(k)) {
                    vm.form_error[k] = data.error[k];
                }
            }
          }
          if(data.hasOwnProperty('message')) {
            vm.$c.response_notify(vm.$q, data.success, data.message);
          }
        }
        vm.$q.loading.hide();
      });
    },
    handle_comment_delete: function(row, idx) {
      var vm = this;
      console.log(Object.keys(row)+'handle'+Object.values(row)+''+idx);
      vm.$q.dialog({
        title: '확인',
        message: '정말 삭제하시겠습니까?',
        cancel: true,
        persistent: true
      }).onOk(() => {
        delete_comment()
      }).onCancel(() => {

      });

      function delete_comment() {
        vm.$q.loading.show();
        console.log(row.CommentId+'delete'+idx);
        axios.delete('/api/comments/' + row.CommentId, {
          params: {
            
          }
        }).then(function(response) {
          var data = response.data;
          if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
              vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
              vm.$router.push("/user/login");
              return;
          }
          if(data.success) {
            vm.params.data.Comments.splice(idx, 1);
            var node = vm.params.node;
            node.setDataValue('CommentCount', vm.params.data.Comments.length);
            vm.$refs.comment_list.$forceUpdate();
            vm.$c.response_notify(vm.$q, data.success, data.message);
          } else {
            if(data.hasOwnProperty('error')) {
              for(var k in data.error) {
                  if(vm.form_error.hasOwnProperty(k)) {
                      vm.form_error[k] = data.error[k];
                  }
              }
            }
            if(data.hasOwnProperty('message')) {
              vm.$c.response_notify(vm.$q, data.success, data.message);
            }
          }
          vm.$q.loading.hide();
        });
      }
    },
  },
  mounted: function() {
    var vm = this;
    var content = this.params.data.Content;
    var reply = this.params.data.Replys;
    vm.content = content;
    vm.reply = reply;
    // if(content) {
    //   var quill = this.$refs.quill_editor.$refs.quill_editor.editor.__quill;
    //   quill.setContents(content);
    //   const delta = quill.clipboard.convert(content);
    //   quill.setContents(this.params.data.delta, 'silent');
    // }
    // if(Object.keys(reply).length > 0) {
    //   var reply_content = reply.Content;
    //   var quill = this.$refs.quill_editor_reply.$refs.quill_editor.editor.__quill;
    //   quill.setContents(reply_content);
    //   const delta = quill.clipboard.convert(reply_content);
    //   quill.setContents(delta, 'silent');
    // }
  }
}
</script>
