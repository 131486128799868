<template>
<q-layout view="lHh lpR fFf">
	<q-page-container>
        <div class="row login-page">
		    <div class="col-5 sidenav">
			    <div class="space"></div>
			    <div class="login-main-text">
			        <h4 class="text-center">AI 개선 리포터 서비스</h4>
                    <p class="text-center">리포터 앱 서비스는 과사람AI의 기능개선과 문제점을 개선하기 위하여 여러분의 관심과 지원을 요청합니다.</p>
                    <p class="text-center">더 나은 과사람을 위하여~</p>
			    </div>
		    </div>
		    <div class="col-7 main">
			    <div class="space"></div>
			    <div class="login-form">
				        <h5 class="mb2">로그인 하기</h5>
                        <div class="q-mb-lg">
                            아이디와 비밀번호를 입력하세요.
                        </div>

					    <form autocomplete="off" @@submit="handleLogin">
                                <div class="q-mt-sm">
                                    <label>아이디</label>
                                </div>
                                <q-input outlined bg-color="gray" class="pb2" placeholder="아이디"
									    :error="(form_error.UserName)?true:false" :error-message="form_error.UserName" 
									    v-model="form.UserName"
                                        @keyup.enter="handleLogin" >
								    <template #prepend>
									    <q-icon name="perm_identity" />
								    </template>
							    </q-input>

                                <div class="q-mt-sm p-relative">
                                    <label>비밀번호</label>
                                    <!-- <span class="absolute-right">비밀번호 찾기</span> -->
                                </div>
                                <q-input outlined class="pb2" placeholder="비밀번호" type="password"
									    :error="(form_error.Password)?true:false" :error-message="form_error.Password" 
									    v-model="form.Password" 
                                        @keyup.enter="handleLogin">
								    <template #prepend>
									    <q-icon name="lock_open" />
								    </template>
							    </q-input>
                                <div class="q-pt-md">
                                    <q-checkbox v-model="remember_password" label="Remember Password" 
                                        @update:model-value="change_remember"/>
                                </div>

                                <div class="q-pt-md">
                                    <label>내 학원</label>
                                </div>
                                <q-select outlined placeholder="센터이름" 
									    :error="(form_error.CenterId)?true:false" :error-message="form_error.CenterId" 
									    :options="center_list" v-model="form.center" >
										    <template #option="scope">
											    <q-item v-bind="scope.itemProps" @click="form.CenterId=scope.opt.CenterId" >
												    <q-item-section >
													    {{scope.opt.CenterName}}
												    </q-item-section>
											    </q-item>
										    </template>
						        </q-select >
					
						    <div >
                                <q-btn color="positive" text-color="white" class="full-width" label="로그인 하기"
                                        @click="handleLogin" ></q-btn>
						    </div>

                            <!-- <div class="q-mt-md">
                                Dont' have an account? <span style="color: #1ea1f7; text-decoration: underline; cursor: pointer;">회원가입</span>
						    </div> -->
					    </form>
				    
			    </div>
            </div>
		</div>
	</q-page-container>
</q-layout>
</template>

<style>
.login-page {
    position: fixed;
    width:100%; height:100%;
}
.login-page .sidenav {
    display:inline-block;
    height: 100%; 
    background-color: #07BF6F;
    overflow-x: hidden;
}
.space { height:20%; }
.login-page .login-main-text{ padding: 60px; color: #fff; }
.login-page .login-main-text p{ color:#fff; }
.login-page  .main {
    display:inline-block;
    height: 100%; 
}
.login-page  .login-form { 
    padding: 0 30%;
}
.btn-black{ background-color: #000 !important; color: #fff; }
</style>

<script>
import axios from "axios";

export default {
    name: 'LoginView',
    data : function() {
        return {
            form : {
                UserName : '',
                Password : '',
                CenterId : 0,
				center: null,
                
            },
            form_error : {},

            remember_password: false,
        }
    },
    computed : {
        center_list : function() {
			this.form.center = this.$store.state.center_list.find( (o) => o.value == this.form.CenterId );
            return this.$store.state.center_list;
        },
    },
    methods : {
        change_remember: function() {
            var vm = this;
            vm.$nextTick(() => {
            });
        },
        /* handle */
        handleLogin : function() {
            let vm = this;
            vm.$c.form.clear_error(this.form_error);
            vm.$q.loading.show();
            if(vm.remember_password == true) {
                localStorage.setItem("r_pwd", JSON.stringify(vm.form.Password));
            } else {
                localStorage.removeItem("r_pwd");
            }
            axios.post('/api/user/login/jwt', this.form).then(function (response) { 
                let data = response.data;
                if(data.success) {
                    //아이디,센터를 쿠키에 저장
                    vm.$c.cookie.set('center_id', vm.form.CenterId, 365*24*60*60);
                    vm.$c.cookie.set('user_name', vm.form.UserName, 365*24*60*60);
                    vm.$store.dispatch("get_user");
                    vm.$router.push("/report");
                } else {
                    vm.$q.loading.hide();
                    vm.$c.form.set_error(data.error, vm.form_error);
                    if(data.message) {
                        vm.$c.response_notify(vm.$q, data.success, data.message);
                    }
                }
            }).catch(function (error) { console.log('error', error.response); });
        },
    },
	beforeMounted : function() {
		this.$c.form.init_error(this.form, this.form_error);
	},
    mounted : function() {
        var vm = this;
        var pwd = localStorage.getItem("r_pwd");
        if(pwd) {
            vm.form.Password = JSON.parse(pwd);
            vm.remember_password = true;
        }
        var CenterId = parseInt(this.$c.cookie.get('center_id'));
        if( isNaN(CenterId) ) { CenterId = 0; }
        this.form.CenterId = CenterId;
        var UserName = this.$c.cookie.get('user_name');
        this.form.UserName = (UserName) ? UserName : "";
    },
}
</script>
