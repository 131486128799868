<template>
  <q-layout view="lHh lpR fFf">
    <app_layout></app_layout>
    <q-page-container class="page_container">
        <div class="q-pa-md page_main">
            <q-card class="q-pa-md page_contents" style="min-width: 1250px;">
                <q-card-section>
                    <q-bar class="bg-white text-black">
                        <p class="text-black q-mr-sm" :WriterUID="form.WriterUID">제목</p>
                        <q-input v-model="form.Title" outlined style="width: 45%;" 
                            clearable @clear="clear_input(form.Title)"
                            @keydown.tab.prevent="FocusOnEditor"
                            :error="form_error.Title.length > 0" :error-message="form_error.Title">
                        </q-input>
                        <q-space />
                        <p class="text-black q-mr-sm">앱</p>
                        <q-select outlined style="width: 150px;" class="bg-white q-mr-sm" v-model="select_app_title" 
                                :options="app_list" @update:model-value="selectApp"
                                :error="form_error.App.length > 0" :error-message="form_error.App">
                            <template v-slot:option="scope">
                                <q-item v-bind="scope.itemProps">
                                    <q-item-section>
                                        <q-item-label>{{ scope.opt.Value }}</q-item-label>
                                    </q-item-section>
                                </q-item>
                            </template>
                        </q-select>
                        <p class="text-black q-mr-sm">요청</p>
                        <q-select outlined style="width: 150px;" class="q-mr-sm" 
                                    @update:model-value="select_type"
                                    :options="type_list" v-model="form.Type" 
                                    :error="form_error.Type.length > 0" :error-message="form_error.Type">
                        </q-select>
                        <p class="text-black q-mr-sm">메뉴</p>
                        <q-select outlined style="width: 150px;" 
                                    @update:model-value="select_page"
                                    :options="page_list" v-model="form.Page" 
                                    :error="form_error.Page.length > 0" :error-message="form_error.Page">
                        </q-select>
                    </q-bar>
                </q-card-section>
                <q-card-section style="padding-top: 0px;">
                    문의내용
                    <quill_editor :height="450"  ref="quill_editor"></quill_editor>
                </q-card-section>
                <q-card-section>
                    <div style="text-align: center">
                        <q-btn icon="save" label="삭제" size="md" class="q-mr-md" color="negative" 
                                @click="handleDelete" v-if="IsAdmin || form.WriterUID == UID" />
                        <q-btn icon="save" label="저장" size="md" class="q-mr-md" color="positive" 
                                @click="handleSave(0)" v-if="IsAdmin || form.WriterUID == UID" />
                        <q-btn icon="close" label="닫기" size="md" class="q-mr-md" color="positive" 
                                @click="close" />
                        <!-- <q-btn icon="cloud" label="임시저장" size="md" class="q-mr-md" color="positive" 
                                @click="handleSave(1)" /> -->
                    </div>
                </q-card-section>
                <q-card-section>
                    <q-bar class="bg-white text-black">
                        <p class="text-black q-mr-sm q-mt-sm" :WriterUID="form.WriterUID">릴리즈 예정일</p>
                        <q-input filled v-model="form.ReleaseDate" mask="date" style="width: 250px;">
									<template v-slot:append>
										<q-icon name="event" class="cursor-pointer">
											<q-popup-proxy ref="qDateProxy" cover transition-show="scale" transition-hide="scale">
												<q-date v-model="form.ReleaseDate" @update:model-value="change_date">
													<div class="row items-center justify-end">
														<q-btn v-close-popup label="Close" color="primary" flat />
													</div>
												</q-date>
											</q-popup-proxy>
										</q-icon>
									</template>
								</q-input>
                        <q-space />
                        <p class="text-black q-mr-sm q-mt-sm">상태</p>
                       <q-select outlined style="width: 150px;" 
							@update:model-value="select_status"
							:options="status_list" v-model="form.Status" >
						</q-select>
                    </q-bar>
                </q-card-section>
                <q-card-section style="padding-top: 0px;">
                    답변
                    <quill_editor :height="450" :key="IsAdmin" :readOnly="!IsAdmin" ref="quill_editor_reply"></quill_editor>
                </q-card-section>
                <q-card-section>
                    <div style="text-align: center">
                        <q-btn icon="save" label="답변" size="md" class="q-mr-md" color="primary" 
                                @click="handleReply" v-if="IsAdmin" />
                        <!-- <q-btn icon="cloud" label="임시저장" size="md" class="q-mr-md" color="positive" 
                                @click="handleSave(1)" /> -->
                    </div>
                </q-card-section>
            </q-card>
        </div>
	</q-page-container>
  </q-layout>
</template>

<script>
import axios from "axios";
import quill_editor from '../../shared/quill_editor.vue'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
export default {
    name: 'ReportView',
    data() {
        return {
            FeedbackId: '',

            search: '',
            form: {
                Title: '',
                Type: '수정',
                Page: '',
                Content: '',
                DisplayName: '',
                IsTentative: 0,
                WriterUID: '',
                App: '',
            },
            center: null,

            form_error: {
                Title: '',
                Type: '',
                Page: '',
                Content: '',
                App: '',
            },

            type_list: ['추가', '수정', '오류', '기타'],
            page_list: ['홈화면', '전체관리', '학생관리', '신입생관리', '퇴원생관리',
                        '교과반관리', '관리반관리', '관심학생관리', '수납관리', '상담관리',
                        '교재관리', '교구관리', '메시지관리', '직원관리', '설정', '공지사항', 
                        '미분류'],
            select_app_title: "",
            status_list: ['검토중', '보류', '진행중', '완료'],
        };
    },
    computed: {
        IsAdmin() {
            return this.$store.state.IsAdmin;
        },
        UID: function() {
            return this.$store.state.UID;
        },
        app_list: function() {
            // if(this.form.App && this.$store.state.app_list.length > 0) {
            //     this.select_app_title = this.$store.state.app_list.find(x=>x.SettingId == this.form.App).Value;
            // }
            return this.$store.state.app_list;
        },  
    },
    components: {
        quill_editor,
    },
    methods: {
        clear_error: function() {
            for(var k in this.form_error) {
                this.form_error[k] = "";
            }
        },
        close: function() {
            var vm = this;
            var content = vm.$refs.quill_editor.QuillEditor.__quill.getContents();
            if(content["ops"][0]["insert"] != '\n') {
                vm.$q.dialog({
                    title: '확인',
                    message: '이 페이지를 벗어나면 마지막 저장 후 수정된 내용은 저장되지 않습니다.',
                    cancel: true,
                    persistent: true
                }).onOk(() => {
                    vm.$router.back();
                    // vm.$router.push("/report");
                }).onCancel(() => {
                    
                });
            } else {
                vm.$router.back();
                // vm.$router.push("/report");
            }
        },
        
        selectApp: function(row) { 
            this.select_app_title = row.Value; 
            this.form.App = row.SettingId;
        },
        select_type: function(val) {this.form.Type = val;},
        select_page: function(val) {this.form.Page = val;},
        select_status: function(value) {
			var vm = this;
			vm.form.Status = value;
		},
        clear_input: function() { var vm = this; },

        FocusOnEditor: function(){      //Tab을 누를 경우 본문으로 Focus 이동
            var vm = this;
            var qe = vm.$refs.quill_editor.QuillEditor.__quill;
            qe.focus();
            qe.setSelection(qe.getLegth(),0);   //Edit의 경우 작성되어 있는 글의 마지막으로 Focus 이동
        },

        handleReply: function() {
			var vm = this;
			vm.form["DisplayName"] = vm.$store.state.DisplayName;
			var html = vm.$refs.quill_editor_reply.$refs.QuillEditor.__quill.root.innerHTML;
            vm.form.Content = html;
			axios.post('/api/reply/' + vm.form.FeedbackId, vm.form).then(function(response) {
				var data = response.data;
                if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
                    vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.9');
                    vm.$router.push("/user/login");
                    return;
                }
				if(data.success) {
					axios.put('/api/system/send',{
						toUID : vm.form.WriterUID,
						MessageData : `${vm.$store.state.DisplayName}님이 "${vm.form.Title}" 글에 답변을 남겼습니다.\nURL : ${window.location.href.replace('edit','detail')}`,
						CenterId : vm.form.CenterId
					}).then(function (send_res) {
						let send_response = send_res.data;
						if (send_response.success) {
							vm.is_open = false;
							vm.$c.response_notify(vm.$q, data.success, data.message);
							vm.callback_func();
						} else {
							if (send_response.hasOwnProperty('error')) {
								for (var k in send_response.error) {
									if (vm.form_error.hasOwnProperty(k)) {
										vm.form_error[k] = send_response.error[k];
									}
								}
							}
							if (send_response.hasOwnProperty('message')) {
                        vm.$c.response_notify(vm.$q, send_response.success, send_response.message);
                    }

						}
					})
                } 
				else {
                    if(data.hasOwnProperty('error')) {
                        for(var k in data.error) {
                            if(vm.form_error.hasOwnProperty(k)) {
                                vm.form_error[k] = data.error[k];
                            }
                        }
                    }
                    if(data.hasOwnProperty('message')) {
                        vm.$c.response_notify(vm.$q, data.success, data.message);
                    }
                }
			})
		},
        handleSave: function(IsTentative) {
            var vm = this;
            var html = vm.$refs.quill_editor.QuillEditor.__quill.root.innerHTML;
            vm.form.Content = html;
            vm.form.IsTentative = IsTentative;
            vm.clear_error();
            var CenterId = vm.$store.state.CenterId;
            var center_list = vm.$store.state.center_list;
            var center = new Object();
            for(var i=0; i<center_list.length; i++) {
                if(center_list[i].CenterId == CenterId) {
                    center = center_list[i];
                    break;
                }
            }
            vm.form.CenterId = center.CenterId;
            vm.form.CenterName = center.CenterName;
            vm.form.DisplayName = vm.$store.state.DisplayName;
            axios.put('/api/report/' + vm.FeedbackId, vm.form).then(function(response) {
                var data = response.data;
                if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
                    vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
                    vm.$router.push("/user/login");
                    return;
                }
                if(data.success) {
                    vm.$c.response_notify(vm.$q, data.success, data.message);
                } else {
                    if(data.hasOwnProperty('error')) {
                        for(var k in data.error) {
                            if(vm.form_error.hasOwnProperty(k)) {
                                vm.form_error[k] = data.error[k];
                            }
                        }
                    }
                    if(data.hasOwnProperty('message')) {
                        vm.$c.response_notify(vm.$q, data.success, data.message);
                    }
                }
            })
        },
        handleDelete: function() {
            var vm = this;
            vm.$q.dialog({
                title: '확인',
                message: '삭제하면 복구 하실 수 없습니다. 정말 삭제 하시겠습니까?',
                cancel: true,
                persistent: true
            }).onOk(() => {
                delete_report();
            }).onCancel(() => {
                
            });

            function delete_report() {
                axios.delete('/api/report/' + vm.FeedbackId).then(function(response) {
                    var data =response.data;
                    if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
                        vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
                        vm.$router.push("/user/login");
                        return;
                    }

                    vm.$q.dialog({
                        title: data.success ? '확인': '에러',
                        message: data.message
                    }).onOk(() => {
                        vm.$router.push("/report");
                    }).onCancel(() => {
                        vm.$router.push("/report");
                    }).onDismiss(() => {
                        vm.$router.push("/report");
                    });
                })
            }
        },
        load_report: function(FeedbackId) {
            var vm = this;
            vm.$q.loading.show();
            axios.get('/api/report/' + FeedbackId).then(function(response) {
                var data =response.data;
                if(data.hasOwnProperty('is_logged') && data.is_logged == false) {
                    vm.$c.response_notify(vm.$q, data.success, '로그인 토큰이 만료되었습니다. 다시 로그인 해주세요.');
                    vm.$router.push("/user/login");
                    return;
                }

                if(data.success == 0) {
                    vm.$q.dialog({
                        title: '에러',
                        message: data.message
                    }).onOk(() => {

                    }).onCancel(() => {

                    }).onDismiss(() => {

                    });
                    return;
                }

                var content = data.report.Content;
                if(content) {
                    var quill = vm.$refs.quill_editor.QuillEditor.__quill;
                    quill.setContents(content);
                    const delta = quill.clipboard.convert(content);
                    quill.setContents(delta, 'silent');
                }
                if(data.report.Reply.Content) {
                    var quill = vm.$refs.quill_editor_reply.QuillEditor.__quill;
                    quill.setContents(data.report.Reply.Content);
                    const delta = quill.clipboard.convert(data.report.Reply.Content);
                    quill.setContents(delta, 'silent');
                }
                // vm.$refs.quill_editor_reply.QuillEditor.__quill.disable();
                if(vm.app_list.length > 0) {
                    vm.select_app_title = vm.app_list.find(x=>x.SettingId == data.report.App).Value;
                } else {
                    vm.select_app_title = "";
                }
                vm.form = data.report;
                vm.$q.loading.hide();
            })
        },
    },
    beforeMount : function() {
		this.$c.form.init_error(this.form, this.form_error);
	},
    mounted: function() {
        var vm = this;
        vm.FeedbackId = vm.$router.currentRoute.value.params.FeedbackId;
        if(vm.FeedbackId == 0) {
            vm.$q.dialog({
                title: '에러',
                message: '경로가 잘못되었습니다.'
            }).onOk(() => {
                vm.$router.push("/report");
            }).onCancel(() => {
                vm.$router.push("/report");
            }).onDismiss(() => {
                vm.$router.push("/report");
            })
        }
        else { 
            vm.load_report(vm.FeedbackId);     
        }
    },
}
</script>

<style>
.q-bar--standard{
    font-size:15px;
}

</style>