<template>
    <div>
        <div id="editor-container" style="min-height: 200px;" :style="{ backgroundColor: (readOnly) ? '#eee' : 'white' }"
            ref="QuillEditor" theme="snow" :readOnly="readOnly"></div>
    </div>
</template>
  
<script>
import { defineComponent, ref, reactive, onMounted, getCurrentInstance } from 'vue'
import Quill from 'quill'
import QuillImageDropAndPaste, { ImageData } from 'quill-image-drop-and-paste'
import ImageResize  from 'quill-image-resize-module'
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import axios from "axios";

const Delta = Quill.import('delta')
var Size = Quill.import('attributors/class/size')
 Size.whitelist = ['small','normal', 'large','extra_large'] //사이즈 변경 시 whiteList 수정 및 toolbar_size 수정으로 적용.
Quill.register('modules/imageDropAndPaste', QuillImageDropAndPaste)
Quill.register('modules/imageResize',ImageResize);
Quill.register(Size,true)

export default defineComponent({
    name: 'quill_editor',
    props: {
        height: { type: Number, default: 100 },
		readOnly: { type: Boolean, default: false },
        quill_key: {type: String, default: '', },
    },
    setup: () => {
        const quill = ref(null)
        const QuillEditor = ref(null)
        const blobUrl = ref(null)
        const image = reactive({
            type: '', // image's mimeType
            dataUrl: null, // image's base64 string
            blob: null, // image's BLOB object
            file: null, // image's File object
        })

        const isUrl = (str) => {
            try {
                return Boolean(new URL(str))
            } catch (e) {
                return false
            }
        }

        const imageHandler = (dataUrl, type, imageData) => {
            // imageData.minify({         이미지 minify
            //     maxWidth: 320,
            //     maxHeight: 320,
            //     quality: .7
            // }).then((miniImageData) => {                
            // })
            const blob = imageData.toBlob()
            const file = imageData.toFile()
            image.type = type
            image.dataUrl = dataUrl
            image.blob = blob
            image.file = file
            blobUrl.value = URL.createObjectURL(blob)
            //upload
            let vm = this
            var form_data = new FormData();
            form_data.append('upload_file', file);
            axios.post('/api/quill/upload/image', form_data).then(function (response) {
                var data = response.data;
                if (data.success == 1) {
                    if(data.fileName.split('feedbackImages\\')[1]){
                        var url = "/feedbackImages/" + data.fileName.split('feedbackImages\\')[1];
                    }
                    else var url = "/feedbackImages/" + data.fileName;
                    let insert_index = QuillEditor._value.__quill.getSelection().index;
                    QuillEditor._value.__quill.insertText(insert_index, '  ', "user");
                    QuillEditor._value.__quill.insertEmbed(insert_index + 1, 'image', url, "user");
                    QuillEditor._value.__quill.setSelection(insert_index + 2);
                } else {
                    throw new Error("파일 업로드에 실패하셨습니다. 다시 시도하거나 개발자에게 문의하세요.")
                }
            }).catch(function (err) {
                console.log("err:", err);
            });

        }

        const textPasteHander = (text) => {
            return isUrl(text)
                ? new Delta().insert(text, { link: text })
                : new Delta().insert(text)
        }

        onMounted(() => {
            quill.value = new Quill(QuillEditor.value, {
                modules: {
                    imageResize: !getCurrentInstance().vnode.props.readOnly,
                    toolbar: {
                        container: [
                            ['bold', 'italic', 'underline', 'strike'],        // toggled buttons
                            [{ 'size': ['small','normal','large','extra_large']}],
                            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
                            [{ 'color': [] }, { 'background': [] }],          // dropdown with defaults from theme
                            [{ 'font': [] }],
                            [{ 'align': [] }],
                            ['blockquote', 'code-block'],
                            ['image', 'video', 'link'],
                            ['clean']
                        ],
                    },
                    imageDropAndPaste: {
                        handler: imageHandler
                    },
                    clipboard: {
                        matchers: [
                            // your custom paste handler
                            [Node.TEXT_NODE, (node, delta) => textPasteHander(node.data, delta)],
                        ]
                    }
                },
                readOnly : getCurrentInstance().vnode.props.readOnly,
                theme: 'snow'
            })
            if (getCurrentInstance().vnode.props.readOnly) {
                quill.value.disable()
                const toolbar = document.querySelector('.ql-toolbar:not(.already)');
                if (toolbar) {
                    toolbar.querySelectorAll('button, select').forEach(el => {
                        el.disabled = true;
                    });
                    toolbar.querySelectorAll(".ql-picker").forEach(el => {
                        el.setAttribute('disabled', true);
                    });
                }
                toolbar.classList.add('already');
            }else{
                const toolbar = document.querySelector('.ql-toolbar:not(.already)');
                toolbar.classList.add('already');
                const clickEvent = new MouseEvent('click',{
                    bubbles:true,
                    cancelable:true,
                    view:window
                });
                quill.value.focus();
                toolbar.querySelector('span[data-value=normal]').dispatchEvent(clickEvent)
            }
            quill.value
                .getModule('toolbar')
                .addHandler('image', function (clicked) {
                    if (clicked) {
                        let fileInput = this.container.querySelector(
                            'input.ql-image[type=file]'
                        );
                        if (fileInput == null) {
                            fileInput = document.createElement('input');
                            fileInput.setAttribute('type', 'file');
                            fileInput.setAttribute(
                                'accept',
                                'image/*'
                            );
                            fileInput.classList.add('ql-image');

                            fileInput.addEventListener('change', function (e) {
                                const files = e.target.files;
                                let file;
                                if (files.length > 0) {
                                    file = files[0];
                                    const type = file.type;
                                    const reader = new FileReader();
                                    reader.onload = (e) => {
                                        // handle the inserted image
                                        const dataUrl = e.target.result;
                                        imageHandler(
                                            dataUrl,
                                            type,
                                            new ImageData(dataUrl, type, file.name)
                                        );
                                        fileInput.value = '';
                                    };
                                    reader.readAsDataURL(file);
                                }
                            });
                        }
                        fileInput.click();
                    }
                });

        })

        return {
            image,
            blobUrl,
            QuillEditor
        }
    },
})
</script>
  
<style scoped>
#editor-container {
    height: 320px;
}


</style>
<style>
.ql-size-small{
    font-size: 1rem !important
}
.ql-size-normal{
    font-size: 1.1rem !important
}
.ql-size-large{
    font-size: 2rem !important
}

.ql-size-extra_large{
    font-size: 4rem !important
}

.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="small"]::before {
    font-size: 1rem !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="normal"]::before {
    font-size: 1.1rem !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="large"]::before {
    font-size: 2rem !important;
}
.ql-snow .ql-picker.ql-size .ql-picker-item[data-value="extra_large"]::before {
    content: 'Extra Large';
    font-size: 4rem !important;
}
.ql-picker-label[data-value="extra_large"]::before {
    content: 'Extra Large' !important;
}

li::marker{
    content: '' !important;
}

li:has(span[class="ql-size-small"]){
    font-size: 1rem !important;
}
li:has(span[class="ql-size-normal"]){
    font-size: 1.1rem !important;
}
li:has(span[class="ql-size-large"]){
    font-size: 2rem !important;
}
li:has(span[class="ql-size-extra_large"]){
    font-size: 4rem !important;
}


</style>
