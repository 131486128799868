<template>
  <div v-if="IsTentative">
    <q-btn color="grey-5" size="sm" dense icon="cloud_queue" label="작성중" 
      style="width: 65px;" />
  </div>
  <div v-else>
    <q-btn v-if="status=='진행중'" color="light-blue-4" size="sm" dense icon="battery_4_bar" label="진행중"
    style="width: 65px;" />
    <q-btn v-else-if="status=='완료'" color="positive" size="sm" dense icon="battery_full" label="완료" 
      style="width: 65px;" />
    <q-btn v-else-if="status=='검토중'" color="negative" size="sm" dense icon="watch_later" label="검토중" 
      style="width: 65px;" />
    <q-btn v-else-if="status=='보류'" color="primary" size="sm" dense icon="block" label="보류"
      style="width: 65px;"/>
  </div>
</template>

<script>
export default {
  name: 'statusComponent',
  data() {
    return {
      status: '',
      IsTentative: 0,
    }
  },
  setup(props) {
    const status = props.params.data.Status;
    const IsTentative = props.params.data.IsTentative;
    return {
      status,
      IsTentative,
    };
  }
}
</script>
