<template>
  <div>
		<div class="row">
			{{ params.displayName }}
			<div v-if="params.enableSorting" class="customSortLabel">
				<i class="q-icon material-icons" 
          @click="onSortRequested('asc',$event)" v-if="sorting==''" >unfold_more</i>
				<i class="q-icon material-icons" style="transform: rotate(90deg);" 
          @click="onSortRequested('desc',$event)" v-else-if="sorting=='asc'">switch_right</i>
				<i class="q-icon material-icons" style="transform: rotate(90deg);" 
          @click="onSortRequested('',$event)" v-else-if="sorting=='desc'" >switch_left</i>
			</div>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import { shallowRef,  ref, computed } from 'vue'

export default {
  name: 'customHeaderVue',
  data() {
    return {
      sorting: '',
    }
  },
  components: {

	},
  setup(props) {
    return {
    };
  },
  computed: {
		
  },
  
  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },

    onSortChanged() {
      if (this.params.column.isSortAscending()) {
        this.sorting = 'asc';
      } else if (this.params.column.isSortDescending()) {
        this.sorting = 'desc';
      } else {
        this.sorting = '';
      }
    },

    onSortRequested(order, event) {
      if(!this.params.field) {
        console.log("field 가 설정되지 않았습니다.");
        return;
      }
      this.params.setSort(order, event.shiftKey);
      this.params.changeSort(this.params.field, order);
    },
  },
	mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },
}
</script>
